.sweet-alert {
    padding: 10px
}

.sweet-alert h2 {
    font-size: 1.2em;
    margin: 15px 0px;
}

.sweet-alert p {
    font-size: 14px;
}

.sweet-alert button {
    font-size: 14px;
    font-weight: 600;
    margin: 10px 5px 0px;
    padding: 10px 20px;
}

/*
.sweet-overlay {
    background: rgba(10, 10, 10, 0.6);
}

.sweet-alert {
    font-family: sans-serif;
    padding: 24px;
    padding-top: 64px;
    padding-bottom: 13px;
    text-align: right;
    border-radius: 0;
    box-shadow: 0 0 14px rgba(0, 0, 0, 0.24), 0 14px 28px rgba(0, 0, 0, 0.48);

}

.sweet-alert h2 {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: auto;
    font-weight: 400;
    color: #212121;
    margin: 20px 0;
    font-size: 1.2em;
    line-height: 1.25;
    text-align: left;
    padding: 0 24px;
}

.sweet-alert p {
    display: block;
    text-align: center;
    color: #212121;
    font-weight: 400;
    font-size: 14px;
    margin: 20px 0;
}

.sweet-alert button {
    border-radius: 3px;
    box-shadow: none !important;

    border-radius: 2px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    padding: 6px 12px;
    position: relative;
    margin-top: 0;
}


.sweet-alert button.confirm {
    color: #FFF;
}

.sweet-alert button.cancel {
    color: #FFF;
}

.sweet-alert button.cancel:focus {
    box-shadow: none !important;
}

.sweet-alert .sa-icon:not(.sa-custom) {
    transform: scale(0.8);
    margin-bottom: -10px;
    margin-top: -10px;
}

.sweet-alert input {
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #c9c9c9;
    color: #212121;
    margin-bottom: 8px;
    padding: 1px;
    padding-bottom: 8px;
    height: auto;
    box-shadow: none;
    font-size: 13px;
    margin: 10px 0;
}

.sweet-alert input:focus {
    border: none;
    border-bottom: 1px solid #3c80f6;
    box-shadow: inset 0 -1px 0 #3c80f6;
}

.sweet-alert fieldset {
    padding: 0;
}

.sweet-alert fieldset .sa-input-error {
    display: none;
}

.sweet-alert .sa-error-container {
    display: none;
    background: none;
    height: auto;
    padding: 0 24px;
    margin: 0 -20px;
    text-align: left;
}

.sweet-alert .sa-error-container.show {
    padding: 0 24px;
    display: block;
}

.sweet-alert .sa-error-container.show ~ fieldset input {
    background: red;
    border-bottom: 1px solid #d9453c;
    box-shadow: inset 0 -1px 0 #d9453c;
}

.sweet-alert .sa-error-container .icon {
    display: none;
}

.sweet-alert .sa-error-container p {
    color: #d9453c;
    margin-top: 0;
}

@-webkit-keyframes animateErrorIcon {
    0% {
        transform: rotateX(100deg), scale(0.5);
        -webkit-transform: rotateX(100deg), scale(0.5);
        opacity: 0;
    }

    100% {
        transform: rotateX(0deg), scale(0.5);
        -webkit-transform: rotateX(0deg), scale(0.5);
        opacity: 1;
    }
}

@keyframes animateErrorIcon {
    0% {
        transform: rotateX(100deg), scale(0.5);
        -webkit-transform: rotateX(100deg), scale(0.5);
        opacity: 0;
    }

    100% {
        transform: rotateX(0deg), scale(0.5);
        -webkit-transform: rotateX(0deg), scale(0.5);
        opacity: 1;
    }
}
*/
